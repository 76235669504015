class Card extends HTMLElement {
  constructor() {
    super();
    const template = document.createElement("template");

    template.innerHTML = `
      <head>


        <title>Offer Screen</title>
        <!-- fonts -->
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap" rel="stylesheet">
        <!-- style sheet -->
<style>
body {
  background-image: url("mainbackground1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

body,
html {
  overflow-x: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

* {
  box-sizing: border-box;
  
}

img {
  max-width: 100%;
  
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}

.main_body {
  display:none;
  padding-top: 25px;
  padding-bottom: 25px;
  margin: 0 !important;


}

.main_body__inner {
  max-width: 330px;
  margin: 0 auto;


}

.travelCard {
  width: 100%;
  border-radius: 32px;
  background-color: #fff;
  border: 1px solid #809099;

}

.travelCard_head {
  background-color: var(--color);
  border-radius: 32px 32px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
}

.travelCard_body {
  padding-top: 17px;
}

.travelCard_body .card_title {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
  color: var(--headerFontColor);
  text-align: center;
}

.travelCard_body .scanImg {
  max-width: 210px;
  width: 100%;
  height: 210px;
  margin: 20px auto;

}

.travelCard_body .scanImg img {
  width: 100%;
}

.travelCard_body .personName {
  
  max-width: 270px;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color:  var(--fontcolor);
  margin: 0 auto ;

}
.personName2 {
  max-width: 270px;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color:  var(--fontcolor);
  margin: 0 auto 19px;
}

.travelCard_body .personSpecs {
  display: flex;
  flex-wrap: wrap;
}

.travelCard_body .personSpecs .specsBox {
  max-width: 50%;
  width: 100%;
  flex: 0 0 50%;
  border-top: 1px solid #809099;
  padding: 5px 0px 16px;
  text-align: center;
}

.travelCard_body .personSpecs .specsBox:nth-of-type(1), .travelCard_body .personSpecs .specsBox:nth-of-type(3) {
  border-right: 1px solid #809099;
}

.travelCard_body .personSpecs .specsBox p {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: rgba(0, 34, 52, 0.5);
  margin-bottom: 5px;
}

.travelCard_body .personSpecs .specsBox h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 36px;
  text-align: center;
  color: rgba(0, 34, 52, 0.5);
}

.travelCard_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--footercolor);
  border-radius: 0px 0px 32px 32px;
  padding: 10px;
}

.travelCard_footer h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  color: #FFFFFF;
}
#loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 24px;
  background-color: white;
}

#loading-text {
  margin-right: 10px;
}

.spinner {
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

#loader {
  height: 10px;
  width: 290px;
position:relative;
margin : 0 auto;
margin-bottom:5px;
overflow-x:hidden;

}

#line-base {
  height: 100%;
  width: 100%;
  background: #E0E0EB;
  border-radius: 25px;
  position: absolute;
  top: 0;

}

#line {
  height: 100%;
  width: 100px;
  background: var(--bar-color);
  border-radius: 25px;
  animation-name: slide;
  animation-duration: 7s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes slide {
  0% {
    transform: translateX(1px);
    background-color: #B65BF5;
    width: 40px;
  }
  
  25% {
    transform: translateX(90px);
    background-color: blue;
    width: 100px;
  }
  
  50% {
    transform: translateX(250px);
    background-color: #F33878;
    width: 40px;
  }
  
  75% {
    transform: translateX(90px);
    background-color: #B65BF5;

    width: 100px;
  }
  
  100% {
    transform: translateX(1px);
    background-color: blue;
    width: 40px;
  }
}

@media screen and (max-height: 783px) and (min-height: 700px) {
  .travelCard_body .scanImg {

    height: 210px;
    margin: 6px auto;
  }
  
  .travelCard_body .scanImg img {
  height:100%
  }
  .main_body {
    margin-top: 38px;
 
  }
}



@media screen and (max-height:700px){
  .travelCard_body .scanImg {

    height: 185px;
    margin: 5px auto;
  }
  
  .travelCard_body .scanImg img {
  height:100%
  }
  .main_body {
    margin-top: 34px;
 
  }
}
@media screen and (min-width: 322px) and (max-width: 370px) {
  .main_body__inner {
    max-width: 300px;

  }

  .travelCard_footer h2 {

    font-size: 17px;
 
  }

  #loader {
   
    width: 240px;
 
  }
  

  
  @keyframes slide {
    0% {
      transform: translateX(1px);
      background-color:  #B65BF5;
      width: 30px;
    }
    
    25% {
      transform: translateX(63px);
      background-color: blue;
      width: 90px;
    }
    
    50% {
      transform: translateX(210px);
      background-color: #F33878;
      width: 30px;
    }
    
    75% {
      transform: translateX(63px);
      background-color: #B65BF5;
      width: 90px;
    }
    
    100% {
      transform: translateX(1px);
      background-color: blue;
      width: 30px;
    }
  }


}
@media screen and (max-width:321px){
  .main_body__inner {
    max-width: 270px;

  }
  .travelCard_body .personSpecs .specsBox h5 {
   
    font-size: 17px;
   
  }

  #loader {
   
    width: 230px;
 
  }
  

  
  @keyframes slide {
    0% {
      transform: translateX(1px);
      background-color:  #B65BF5;
      width: 30px;
    }
    
    25% {
      transform: translateX(60px);
      background-color: blue;
      width: 90px;
    }
    
    50% {
      transform: translateX(200px);
      background-color: #F33878;
      width: 30px;
    }
    
    75% {
      transform: translateX(60px);
      background-color: #B65BF5;
      width: 90px;
    }
    
    100% {
      transform: translateX(1px);
      background-color: blue;
      width: 30px;
    }
  }
  .travelCard_footer h2 {

    font-size: 16px;
 
  }
 

}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  background-color: white;
}
#bar-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.TopBar {
  height: 50px;
  width:100%;
  background-color: green;
  
  
  }

  .bar {
    height:100%;
    width:100%;
    background-color: red;

  }

#loading-text {
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}














</style>
</head>
      <body>
        <div class="loader">
          <span id="loading-text">Loading...</span>
          <div class="spinner"></div>
        </div>
        <main class="main_body">
        <div class="main_body__inner">
        <!-- tarvel card -->
        <div class="travelCard">
          <!-- card head -->
            <div class="travelCard_head">
            <svg width="62" height="32" viewBox="0 0 62 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.6816 7.72744C27.7709 7.53438 25.5702 8.99725 24.2779 10.4912C23.394 7.98309 21.7347 6.42995 19.4683 5.625C19.6219 2.85455 21.3744 0.597029 24.1495 0.0992397C25.9862 -0.229927 27.5561 0.296942 28.7068 1.13137C30.4249 2.37696 31.6232 5.15347 30.6816 7.72744Z" fill="#FF0001"/>
            <path d="M43.7708 10.1859C40.7717 9.91245 38.4555 11.3876 37.2103 12.856C36.3437 10.4209 34.7345 8.90173 32.5244 8.12364C32.6855 5.30694 34.4422 3.06174 37.2755 2.55345C39.0627 2.23317 40.7443 2.79901 41.8392 3.65848C43.4828 4.94849 44.663 7.60384 43.7708 10.1859Z" fill="#FF0001"/>
            <path d="M16.2358 7.06523C18.605 6.64519 20.7837 7.73266 21.9191 9.37728C22.5047 10.226 22.9439 11.3262 22.992 12.6114C23.1037 15.6359 20.8338 18.2218 17.6898 18.533C14.7483 18.8238 12.2537 16.8003 11.5721 14.1706C11.1698 12.6183 11.5305 11.1156 11.9984 10.1552C12.6549 8.80841 14.1992 7.42712 16.2358 7.06523Z" fill="#FF0001"/>
            <path d="M29.1792 9.53885C32.0228 9.01582 34.1977 10.5116 35.2147 12.4022C37.1626 16.0283 34.9447 20.2323 31.2841 20.9016C28.2374 21.4587 25.8229 19.7358 24.8766 17.6358C24.0624 15.8304 24.3825 13.714 25.1379 12.3875C25.9586 10.947 27.2387 9.89568 29.1792 9.53885Z" fill="#FF0001"/>
            <path d="M42.119 11.9762C44.7701 11.4776 46.9297 13.0443 47.8467 14.3571C49.6559 16.9492 48.7167 20.0737 47.3128 21.582C45.633 23.3878 42.6792 24.0502 40.2656 22.7587C38.2082 21.6579 36.5452 18.6704 37.5868 15.7584C38.1829 14.0895 39.6484 12.4407 42.119 11.9762Z" fill="#FF0001"/>
            <path d="M11.5969 20.3286C11.6159 21.159 11.6141 21.8907 11.6068 22.6643C12.2585 22.6403 12.8045 22.587 13.4451 22.5597C13.4546 21.7764 13.4944 20.9842 13.4683 20.1841C13.9491 20.1645 14.2472 20.1309 14.7208 20.1358C14.7519 21.695 14.7173 23.7859 14.75 25.647C14.4534 25.6385 14.0212 25.6563 13.5707 25.6878C13.5337 24.9392 13.5444 23.7792 13.503 23.1385C13.1432 23.1494 12.152 23.2126 11.6641 23.2431C11.6575 23.9955 11.6421 24.9049 11.6262 25.8393C11.0751 25.9221 11.0054 25.9221 10.3844 25.9958C10.4115 23.8441 10.3465 22.2225 10.3953 20.5185C10.8 20.4547 11.1997 20.3973 11.5969 20.3286Z" fill="white"/>
            <path d="M9.49122 22.2116C9.04634 22.2914 8.45464 22.4475 8.08085 22.5305C8.05803 22.0024 7.83428 21.5038 7.25147 21.625C6.57173 21.7663 6.1113 22.9396 6.11857 23.9986C6.12463 24.8833 6.32758 25.7337 6.94916 26.0356C7.76137 26.2848 8.71717 25.5366 9.2291 25.1582C9.1986 25.3999 9.20305 25.6699 9.23455 25.9609C8.66891 26.3864 7.83306 26.7105 7.02832 26.8315C5.74679 27.0251 5.00404 26.3208 4.86026 25.1059C4.68699 23.643 5.23769 22.0634 6.37968 21.4172C7.45967 20.924 9.41912 20.4529 9.49122 22.2116Z" fill="white"/>
            <path d="M3.62799 22.3765C3.65182 22.6622 3.62314 22.9052 3.63364 23.1798C3.10738 23.1279 2.49933 23.1435 2.02193 23.3355C1.64975 23.4858 1.08371 23.9591 1.28727 24.5023C1.48275 25.0215 2.47469 24.8269 3.15019 25.0157C3.41231 25.089 3.78793 25.3047 3.89677 25.4549C4.26774 25.9691 4.03814 26.8368 3.74289 27.2591C2.97591 28.3552 1.4882 28.6672 0.106909 28.9982C0.0836855 28.6692 0.133566 28.3986 0.0863113 28.0509C1.13783 28.1165 2.62675 27.9691 2.82809 26.9824C3.07123 25.7887 1.03383 26.1936 0.416892 25.7918C-0.475898 25.2085 0.267252 23.8608 0.77312 23.4201C1.4468 22.8335 2.69057 22.5227 3.62799 22.3765Z" fill="white"/>
            <path d="M26.8853 22.3557C27.4986 24.358 28.2246 26.3209 28.8504 28.3734C28.5194 28.2997 28.0562 28.1664 27.5988 28.0564C27.4136 27.4697 27.2239 26.8879 27.0533 26.2835C26.4138 26.1032 25.9059 25.9695 25.3039 25.7954C25.0918 26.255 24.9228 26.75 24.7441 27.2369C24.3236 27.139 24.0205 27.0547 23.6461 26.9782C24.2025 25.2677 24.943 23.7075 25.5605 22.0457C26.1266 22.1528 26.5388 22.2663 26.8853 22.3557ZM25.4771 25.3039C25.9521 25.4232 26.381 25.5438 26.8421 25.6464C26.5777 24.8057 26.4251 24.1746 26.1436 23.4499C25.9319 24.0769 25.6997 24.6857 25.4771 25.3039Z" fill="white"/>
            <path d="M29.7803 23.2081C31.0489 23.6621 33.7569 23.6284 33.7232 25.6751C33.7145 26.2068 33.3331 26.6327 32.6337 26.621C33.0045 26.8686 33.3539 27.232 33.5285 27.8116C33.7174 28.4409 33.7489 29.1568 33.9385 29.7075C33.4829 29.6204 33.0711 29.5071 32.6501 29.394C32.3866 28.103 32.304 26.7654 31.0776 26.5883C31.0307 27.3591 31.0705 28.2008 31.058 28.9997C30.5763 28.8702 30.2767 28.7915 29.8025 28.6461C29.7728 26.8156 29.8053 25.0353 29.7803 23.2081ZM31.06 26.042C31.8031 26.2825 32.4635 26.0192 32.471 25.3578C32.4794 24.5642 31.6761 24.1859 31.0491 24.0712C31.064 24.7372 31.0461 25.376 31.06 26.042Z" fill="white"/>
            <path d="M34.6706 24.4149C35.9362 24.6597 37.3712 24.9541 38.4772 25.1423C38.5719 25.9174 37.9423 26.5343 37.5947 27.0533C37.0105 27.9251 36.4835 28.669 35.9564 29.5487C36.7193 29.7373 37.8336 29.8874 38.6323 30.0566C38.6089 30.2054 38.6277 30.4833 38.6166 30.6424C37.3064 30.4078 35.8326 30.1814 34.506 29.8351C34.4194 29.1991 34.8188 28.8149 35.1353 28.3498C35.7981 27.374 36.4881 26.3903 37.1038 25.4578C36.2131 25.2572 35.6082 25.1621 34.6876 24.9608C34.6522 24.8493 34.6427 24.5749 34.6706 24.4149Z" fill="white"/>
            <path d="M39.1876 25.2196C39.6173 25.3074 39.9485 25.3347 40.392 25.4318C40.621 26.7079 40.9255 28.1724 41.1355 29.194C41.6182 28.0448 41.9944 26.8091 42.4059 25.6023C42.7773 25.6297 43.1275 25.6556 43.4859 25.6804C43.8599 27.0205 44.3531 28.217 44.7125 29.4689C45.0451 28.2459 45.2644 26.9522 45.493 25.7788C45.7461 25.7491 46.2077 25.7501 46.5213 25.7157C46.2162 27.6185 45.8069 29.4376 45.4078 31.2641C45.0397 31.2346 44.59 31.2223 44.1818 31.2003C43.7919 29.957 43.3127 28.6825 42.9114 27.3428C42.3851 28.5005 42.0626 29.8251 41.5741 31.0139C41.1688 30.9651 40.7106 30.9301 40.3449 30.914C39.9624 29.0943 39.5598 27.2406 39.1876 25.2196Z" fill="white"/>
            <path d="M57.4638 29.4049C57.4026 27.6704 57.4438 25.7308 57.4371 23.9301C58.4608 23.6774 59.6185 22.9611 60.9182 23.2285C62.2056 23.9692 61.9667 26.7387 61.0733 27.7779C60.2155 28.7763 58.812 28.98 57.4638 29.4049ZM60.5618 25.6003C60.5559 25.2207 60.5214 24.7952 60.4174 24.5181C60.1514 23.8095 59.3624 23.8049 58.6785 24.1389C58.75 25.6046 58.6357 27.0963 58.7399 28.4776C60.0876 28.2242 60.5848 26.9873 60.5618 25.6003Z" fill="white"/>
            <path d="M54.4119 24.7338C54.4609 26.3698 54.4012 27.9179 54.4458 29.5504C55.0482 29.4248 55.8119 29.2319 56.6149 29.0485C56.6139 29.2931 56.6201 29.4872 56.6028 29.6707C55.2944 29.9819 54.5116 30.2111 53.2063 30.4735C53.1435 28.9532 53.1677 26.8706 53.178 24.9626C53.7329 24.8643 53.888 24.813 54.4119 24.7338Z" fill="white"/>
            <path d="M50.2683 25.4626C50.9409 27.3207 51.5785 28.8292 52.2602 30.6376C51.8388 30.7216 51.5847 30.7594 51.0268 30.8666C50.8287 30.3504 50.6445 29.8432 50.4654 29.3145C49.8056 29.3795 49.3418 29.4237 48.7083 29.4837C48.4962 30.0409 48.3175 30.597 48.1335 31.1465C47.7666 31.182 47.4332 31.2085 47.0307 31.2166C47.5946 29.2793 48.369 27.5133 48.9498 25.5902C49.3591 25.5581 49.7295 25.5032 50.2683 25.4626ZM48.8735 28.9205C49.3 28.8746 49.6019 28.8476 50.2289 28.7898C50.0502 28.2058 49.7646 27.3918 49.5518 26.81C49.3248 27.5127 49.0932 28.2121 48.8735 28.9205Z" fill="white"/>
            <path d="M15.7974 20.1779C16.2579 20.2081 16.5567 20.2142 16.9507 20.2489C17.2662 21.5032 17.4905 22.8332 17.7343 24.0437C18.2292 22.8916 18.6287 21.6848 19.012 20.5242C19.4035 20.566 19.6957 20.6264 20.0629 20.6783C20.4867 21.9871 20.8906 23.2894 21.3436 24.6444C21.6413 23.5059 21.8727 22.2972 22.1419 21.1349C22.5155 21.1938 22.9026 21.3021 23.1631 21.3647C22.7915 23.1735 22.4304 24.7848 22.054 26.5841C21.5554 26.4874 21.2517 26.4189 20.7691 26.3068C20.387 24.9175 19.9092 23.6428 19.5172 22.2649C19.0184 23.4227 18.6729 24.7052 18.1737 25.8634C17.7504 25.8208 17.503 25.8038 16.9434 25.7634C16.5604 23.9098 16.1771 22.0923 15.7974 20.1779Z" fill="white"/>
            </svg>

            </div>
            <!-- card body -->
            <div class="travelCard_body">
            <div id="loader">
            <div id="line-base"></div>
            <div id="line"></div>
          </div>
                <h4 class="card_title">Konus Gästekarte</h4>
                <div class="scanImg">
                <img id="qr-code" alt="qr">
                </div>
                <h3 class="personName">
                
                <span id="vorname">
                </span>
                </h3>
                <h3 class="personName2">
                <span id="name"></span>
                
                </h3>

  
                <div class="personSpecs">
                  <div class="specsBox">
                    <p>Kategorie</p>
                    <h5> <span id="kategorie"></span></h5>
                  </div>
                  <div class="specsBox">
                    <p>Gästekartennr.</p>
                    <h5> <span id="cardNum"></span></h5>
                  </div>
                  <div class="specsBox">
                    <p>Anreise</p>
                    <h5><span id="anreise"></span></h5>
                  </div>
                  <div class="specsBox">
                    <p>Abreise</p>
                    <h5><span id="abreise"></span></h5>
                  </div>
                </div>  
  
            </div>  
  
            <!-- card footer -->
            <div class="travelCard_footer">
              <h2  id="countdown" ></h2>
            </div>
        </div>
      </div>
        </main>
      </body>
    `;

    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
  }

  connectedCallback() {
    const line = this.shadowRoot.querySelector("#line");
    line.style.backgroundColor = this.getAttribute("bar-color") || "red";
    // props for changing color of header and footer
    const headerBackgroundColor =
      this.shadowRoot.querySelector(".travelCard_head");
    headerBackgroundColor.style.backgroundColor =
      this.getAttribute("color") || "#002234";
    const footerBackgroundColor =
      this.shadowRoot.querySelector(".travelCard_footer");
    footerBackgroundColor.style.backgroundColor =
      this.getAttribute("footercolor") || " #459D58";
    const nameColor = this.shadowRoot.querySelector(".personName");
    const nameColor2 = this.shadowRoot.querySelector(".personName2");
    // props for changing color of font for name

    const headerColor = this.shadowRoot.querySelector(".card_title");

    nameColor.style.color = this.getAttribute("fontcolor") || "black";
    nameColor2.style.color = this.getAttribute("fontcolor") || "black";
    headerColor.style.color = this.getAttribute("headerFontColor") || "black";

    const nameElement = this.shadowRoot.querySelector("#name");
    const VorNameElement = this.shadowRoot.querySelector("#vorname");

    // for accesing the div's, using shadow root to store value

    const kategorieElement = this.shadowRoot.querySelector("#kategorie");
    const anreiseElement = this.shadowRoot.querySelector("#anreise");
    const abreiseeElement = this.shadowRoot.querySelector("#abreise");
    const cardNum = this.shadowRoot.querySelector("#cardNum");

    setTimeout(() => {
      // fetching the data, later the time gets changed when real API attached
      this.fetchUserData();
    }, 2000);
    // later we use this url to get hash and make api call
    const url = window.location.href;
    console.log(`The current URL is: ${url}`);
    fetch(
      "https://0cf1592a-ad2a-4f46-9298-5a8a7d77b469.mock.pstmn.io/getDetails"
    )
      .then((response) => response.json())
      .then((data) => {
        // storing the values
        console.log(data.personen[0].personData);
        const vorName = data.personen[0].personData.Vorname;

        const name = data.personen[0].personData.Name;
        const kategorie = data.personen[0].personData.Kategorie;
        const abreise = data.personen[0].personData.Abreise;
        const anreise = data.personen[0].personData.Anreise;
        const qrImg = data.personen[0].personData.qrCodeImagePng;
        const cardNumber = data.personen[0].personData.Meldescheinnummer;
        const qrCodeImg = this.shadowRoot.querySelector("#qr-code");
        qrCodeImg.src = qrImg;

        nameElement.textContent = name;
        VorNameElement.textContent = vorName;
        kategorieElement.textContent = kategorie;
        anreiseElement.textContent = anreise;
        abreiseeElement.textContent = abreise;
        cardNum.textContent = cardNumber;
        this.countdown(abreise);
      })
      .catch((err) => {
        const name = " ";
        const kategorie = "Ungültig";
        const abreise = "Ungültig";
        const anreise = "Ungültig";
        const qrImg =
          "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=Example";
        const cardNumber = "Ungültig";
        const qrCodeImg = this.shadowRoot.querySelector("#qr-code");
        qrCodeImg.src = qrImg;
        nameElement.textContent = name;
        kategorieElement.textContent = kategorie;
        anreiseElement.textContent = anreise;
        abreiseeElement.textContent = abreise;
        cardNum.textContent = cardNumber;
        this.countdown("2022-05-01");
      });
  }

  async fetchUserData() {
    // removing the indicator after certain time when data gets's fetched from API
    const loader = this.shadowRoot.querySelector(".loader");
    const container = this.shadowRoot.querySelector(".main_body");

    loader.style.display = "none";
    container.style.display = "flex";
  }
  // a function that calculates the time left in promotion and takes abreise as prop
  countdown(abreise) {
    const endDate = new Date(abreise).getTime();
    const countdownElement = this.shadowRoot.querySelector("#countdown");

    const timer = setInterval(function () {
      const now = new Date().getTime();
      const timeLeft = endDate + 1000 * 60 * 60 * 23 - now;

      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

      let countdownText = "";

      if (days > 0 && days < 2) {
        countdownText += `${days} Tag `;
      }
      if (days > 1) {
        countdownText += `${days} Tage `;
      }

      countdownText += ` ${hours} Stunde`;

      countdownElement.textContent = "noch " + countdownText + " gültig";

      if (timeLeft < 0) {
        clearInterval(timer);
        countdownElement.textContent = "Ungültig";
      }
    }, 1000);
  }
}

customElements.define("avs-card", Card);
