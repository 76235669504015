import { useState } from "react";

import "./App.css";
import "./web-component/Card";

function App() {
  return (
    <div className="App">
      <avs-card
        color=""
        footercolor=""
        fontColor=""
        headerFontColor=""
      ></avs-card>
    </div>
  );
}

export default App;
